import React, { useCallback, useRef, createContext } from 'react';
import classNames from 'classnames';
import { joinProvider } from 'join-react-context';
import { Transition, TransitionGroup } from 'react-transition-group';
import { Helmet } from 'react-helmet';
import icon from '@devsisters/ui-common/lib/icon';
import { DevsistersCi } from '@devsisters/ui-common/lib/identity';

import { useWindowWidth, useDomContentLoadedEffect } from '../util';
import NavMenu from './NavMenu';
import Header from './Header';
import * as styles from './Layout.module.scss';

interface Props {
  children?: React.ReactNode;
  location: Location;
}

const Layout: React.FC<Props> = ({ children, location }) => {
  const backToTop = useCallback((e: React.MouseEvent) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    e.preventDefault();
  }, []);
  const windowWidth = useWindowWidth();
  const mediaType = windowWidth >= 1000 ? 'pc' : 'mobile';
  const layoutDiv = useRef<HTMLDivElement>(null);
  useDomContentLoadedEffect(() => {
    // 2019/03/05 크롬72에서 프로덕션 빌드된 페이지 푸터 아래 빈공간 생기는 문제
    // dom이 전부 로드된 다음 강제로 다시 reflow 시켜주면 해결
    const div = layoutDiv.current!;
    div.style.minHeight = 'initial';
    div.offsetHeight; // hack
    div.style.minHeight = null;
  });
  const title = 'DevTech - 데브시스터즈 기술블로그';
  const description = '탁월한 기술, 서비스, 콘텐츠로 전 세계 고객에게 최고의 경험을 선사합니다.';
  return (
    <Provider value={[mediaType]}>
      <div ref={layoutDiv} className={classNames(styles.layout, styles[mediaType])}>
        <Helmet>
          <title>{title}</title>
          <meta property="og:title" content={title} />
          <meta property="og:site_name" content={title} />
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={`${process.env.PUBLIC_URL}${location.pathname}`} />
          <meta property="og:image" content={`${process.env.PUBLIC_URL}/opengraph.png`} />
        </Helmet>
        <Header location={location} />
        <TransitionGroup component={null}>
          <Transition key={location.pathname} timeout={300}>
            {state => (
              <div id="top" className={classNames(styles.page, styles[state])}>
                <main>{children}</main>
                <Footer mediaType={mediaType} />
                <a href="#top" className={styles.backToTop} onClick={backToTop}>
                  <BackToTopIcon />
                </a>
              </div>
            )}
          </Transition>
        </TransitionGroup>
      </div>
    </Provider>
  );
};

export default Layout;

export type MediaType = 'pc' | 'mobile';
export const mediaTypeContext = createContext<MediaType>('mobile');

type EveryContext = [typeof mediaTypeContext];
const Provider = joinProvider<EveryContext>([mediaTypeContext]);

const BackToTopIcon = icon(
  '0 0 57 57',
  <g className={styles.backToTopIcon}>
    <polyline points="40.72,24.44 28.5,12.22 16.28,24.44" />
    <line x1="28.5" y1="12.22" x2="28.5" y2="44.78" />
    <circle cx="28.5" cy="28.5" r="28" />
  </g>
);

interface FooterProps {
  mediaType: MediaType;
}
const Footer: React.FC<FooterProps> = ({ mediaType }) => {
  return (
    <footer className={classNames(styles.footer, styles[mediaType])}>
      <div className={styles.wrap}>
        <DevsistersCi className={styles.logo} />
        <p>© {new Date().getFullYear()} Devsisters Corp. All Rights Reserved.</p>
      </div>
    </footer>
  );
};
