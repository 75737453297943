type Internal = {
  type: 'internal',
  url: {
    pathname: string,
    search: string,
    hash: string,
  },
};

type External = {
  type: 'external',
  url: URL,
};

export type LinkType = (
  | Internal
  | External
);

export function isInternalLink(link: LinkType): link is Internal {
  return link.type === 'internal';
}

export function isExternalLink(link: LinkType): link is External {
  return link.type === 'external';
}

export function linkToString(link: LinkType): string {
  return mapLinkType(link, {
    internal: link => link.url.pathname + link.url.search + link.url.hash,
    external: link => link.url.href,
  });
}

export function linkIsEqual(link1: LinkType, link2: LinkType): boolean {
  return linkToString(link1) === linkToString(link2);
}

const localOrigin = process.env.PUBLIC_URL;

export function parseLink(urlString: string): LinkType {
  const startsWithPath = urlString.startsWith('/');
  let url: URL | null = null;
  try {
    url = new URL(`${startsWithPath ? localOrigin : ''}${urlString}`);
  } catch { }

  if (!url) {
    throw new Error(`Failed to parse link, invalid format. url: ${url}`);
  }

  const origin = url?.origin ?? localOrigin;
  const sameOrigin = origin === localOrigin;
  return { type: sameOrigin ? 'internal' : 'external', url };
}

export function mapLinkType<
  ResultForInternal,
  ResultForExternal
>(link: LinkType, range: {
  internal: (link: Internal) => ResultForInternal,
  external: (link: External) => ResultForExternal,
}): ResultForInternal | ResultForExternal {
  if (isInternalLink(link)) {
    return range.internal(link);
  } else {
    return range.external(link);
  }
}
