import { useEffect, useRef, useState } from 'react';

export const wait = async (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export function useClipboard<T extends HTMLElement>(text: string, alertMessage: string) {
  const ref = useRef<T>(null);
  useEffect(() => {
    if (!ref.current) throw new Error('clipboard ref not used');
    const Clipboard: typeof import('clipboard') = require('clipboard');
    const clipboard = new Clipboard(ref.current, { text: () => text });
    clipboard.on('success', () => alert(alertMessage));
    return () => clipboard.destroy();
  }, [text]);
  return ref;
}

export function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(1);
  useEffect(() => {
    const resize = () => setWindowWidth(window.innerWidth);
    resize();
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, []);
  return windowWidth;
}

export function useDomContentLoadedEffect(callback: () => void) {
  useEffect(() => void domContentLoaded.then(callback), []);
}

const domContentLoaded = new Promise(resolve => {
  if (typeof window === 'undefined') return resolve();
  if (document.readyState === 'interactive' || document.readyState === 'complete') {
    return resolve();
  }
  window.addEventListener('DOMContentLoaded', () => resolve());
});

export interface Avatar {
  id: string;
  filename: string;
}
export function findAvatar(avatars: Avatar[], avatarId: string) {
  return avatars.find(avatar => avatar.id === avatarId);
}

export function parseEmailToAvartarId(emails: string[]) {
  return emails.map(v => v.slice(0, v.indexOf('@'))).join('.');
}

export function getAvatarImage(avatars: Avatar[], emails: string[]) {
  const avatar =
    findAvatar(avatars, parseEmailToAvartarId(emails)) ??
    findAvatar(avatars, parseEmailToAvartarId(emails.slice(0, 1)));
  if (avatar) return require(`../techblog-contents/avatars/${avatar.filename}`);
  return require('./images/avatar-default.jpg');
}

export function roughlyIncludes(includer: string, includee: string): boolean {
  if (!String.prototype.normalize) {
    // ie11
    if (!includer.toLowerCase().includes(includee.toLowerCase())) return false;
  } else {
    const _includer = includer.normalize('NFKD').toLowerCase();
    const _includee = includee.normalize('NFKD').toLowerCase();
    if (!_includer.includes(_includee)) return false;
  }
  return true;
}

export function addTrailingSlash(path: string) {
  return path.endsWith('/') ? path : path + '/';
}
