import * as React from 'react';
import { Link as _GatsbyLink, graphql, useStaticQuery } from 'gatsby';
import { usePrismicPreview } from 'gatsby-source-prismic';
import { styled } from '../gatsby-theme-stitches/stitches.config';
import { parseLink, mapLinkType, linkToString, linkIsEqual } from '../utils/link';

import { ReactComponent as NavbarOpenSvg } from './header/navbar-open.svg';
import { ReactComponent as NavbarCloseSvg } from './header/navbar-close.svg';
import { ReactComponent as DevTechWordmark } from './header/devtech-wordmark.svg';
import { ReactComponent as NavigationArrowSvg } from './header/navigation-arrow.svg';

type HeaderProps = {
  location: Location;
};

const rem = (v: number) => `${v}px`; // 이 동네는 단위가 달라.......

const controlId = 'toggle-navigation';

type FIXME = any;

const Container = styled('header', {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1,
  backgroundColor: 'background',
  color: 'text',
  borderBottom: '1px solid border',
  variants: {
    mode: {
      default: {
        height: 'mobileHeader',
      },
      wide: {
        height: 'wideHeader',
      },
    },
  },
});

const Control = styled('input', {
  position: 'fixed',
  top: '-100%',
});

const NavigationBar = styled('nav', {
  $contentWrapper: true,
  backgroundColor: 'background',
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  variants: {
    mode: {
      default: {
        borderBottom: '1px solid primary',
        paddingX: rem(32),
      },
      wide: {
        borderBottom: 'none',
      },
    },
  },
});

const Logo = styled(DevTechWordmark, {
  width: 'auto',
  height: rem(24),
});

const NavigationMenu = styled('ul', {
  listStyle: 'none',
  paddingLeft: 0,
  display: 'flex',
  margin: 0,
  backgroundColor: 'background',
  variants: {
    mode: {
      default: {
        position: 'absolute',
        width: '100%',
        left: 0,
        top: '100%',
        flexDirection: 'column',
        textAlign: 'right',
        paddingY: rem(8),
        marginLeft: 'auto',
        zIndex: -1,
        transition: 'transform 0.3s ease-in',
        transform: 'translateY(-100%)',
        [`#${controlId}:checked ~ * &`]: {
          transform: 'translateY(0)',
        },
        '> li': {
          marginRight: rem(32),
          marginY: rem(8),
        },
      },
      wide: {
        position: 'static',
        width: 'auto',
        flexDirection: 'row',
        zIndex: 0,
        transition: 'none',
        transform: 'none',
        '> li': {
          marginX: rem(32),
        },
      },
    },
  },
});

const NavigationMenuItem = styled('li', {
  position: 'relative',
  fontSize: rem(24),
  variants: {
    active: {
      true: {
        color: 'primary',
        '> a': {
          color: 'primary',
          $animatedUnderlineActive: true,
        },
      },
      false: {
        color: 'text',
      },
    },
  },
});

const NavigationControl = styled('label', {
  position: 'relative',
  color: 'primary',
  width: rem(30),
  height: rem(30),
  marginLeft: 'auto',
  [`#${controlId}:focus ~ * &`]: {
    outline: '1px solid primary',
  },
  '& > .open, & > .close': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    transitionProperty: 'transform, opacity',
    transitionDuration: '0.2s',
    width: '100%',
  },
  '& > .open': {
    opacity: 1,
  },
  '& > .close': {
    opacity: 0,
    transform: 'translate(-50%, -50%) rotate(-45deg)',
  },
  [`#${controlId}:checked ~ * & > .open`]: {
    opacity: 0,
    transform: 'translate(-50%, -50%) rotate(45deg) scaleY(0.2)',
  },
  [`#${controlId}:checked ~ * & > .close`]: {
    opacity: 1,
    transform: 'translate(-50%, -50%)',
  },
  variants: {
    mode: {
      default: {
        display: 'inline-block',
      },
      wide: {
        display: 'none',
      },
    },
  },
});

const GatsbyLink = styled(_GatsbyLink, {
  $animatedUnderline: true,
  color: 'text',
  textDecoration: 'none',
  ':hover, focus': {
    color: 'primary',
    $animatedUnderlineActive: true,
  },
});

const OutboundLink = styled('a', {
  $animatedUnderline: true,
  color: 'text',
  textDecoration: 'none',
  ':hover, focus': {
    color: 'primary',
    $animatedUnderlineActive: true,
  },
});

const NavigationArrowIcon = styled(NavigationArrowSvg, {
  position: 'absolute',
  right: `-${rem(20)}`,
  top: '50%',
  width: rem(14),
  height: rem(14),
  transition: 'opacity 0.3s, transform 0.3s',
  opacity: 0,
  transform: 'translateX(-0.5em) translateY(-50%)',
  'a:hover &, a:focus &': {
    opacity: 1,
    transform: 'translateY(-50%)',
  },
});

const Header: React.FC<HeaderProps> = ({ location }) => {
  const staticData = useStaticQuery<any>(graphql`
    query HeaderStatic {
      prismicSiteNavigation {
        data {
          items {
            label
            link {
              url
            }
          }
        }
      }
    }
  `);

  const { isPreview, previewData, path } = usePrismicPreview({
    // FIXME 환경변수로 주입하기
    repositoryName: 'devsisters',
  });

  const data = React.useMemo(() => {
    if (!isPreview || !previewData?.prismicSiteNavigation) {
      return staticData;
    }
    return previewData;
  }, [staticData, isPreview, previewData]);

  const currentLink = React.useMemo(() => {
    return parseLink(location.pathname);
  }, [location]);

  const items = React.useMemo(() => {
    return data.prismicSiteNavigation.data.items.map((item: FIXME) => ({
      label: item.label,
      link: parseLink(item.link.url),
    }));
  }, [location, data]);

  return (
    <Container mode={{ initial: 'default', md: 'wide' }}>
      <Control type="checkbox" id={controlId} />
      <NavigationBar mode={{ initial: 'default', md: 'wide' }}>
        <Logo />
        <NavigationControl htmlFor={controlId} mode={{ initial: 'default', md: 'wide' }}>
          <NavbarOpenSvg className="open" />
          <NavbarCloseSvg className="close" />
        </NavigationControl>
        <NavigationMenu mode={{ initial: 'default', md: 'wide' }}>
          {items.map((item: FIXME) => (
            <NavigationMenuItem
              key={linkToString(item.link)}
              active={linkIsEqual(item.link, currentLink)}>
              {mapLinkType(item.link, {
                internal: link => <GatsbyLink to={linkToString(link)}>{item.label}</GatsbyLink>,
                external: link => (
                  <OutboundLink href={linkToString(link)} rel="noopener">
                    {item.label}
                    <NavigationArrowIcon />
                  </OutboundLink>
                ),
              })}
            </NavigationMenuItem>
          ))}
        </NavigationMenu>
      </NavigationBar>
    </Container>
  );
};

export default Header;
