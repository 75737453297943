module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-X5V2T18EG8"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/runner/work/web-techblog/web-techblog/src/components/Layout.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Devsisters 기술 블로그","short_name":"Devsisters 기술 블로그","background_color":"#fff","theme_color":"#ff5f00","icon":"src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"9dce5f969c7c2888a235a394755ba1a5"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"devsisters","prismicToolbar":false,"schemas":{"site_navigation":{"Main":{"_meta":{"type":"StructuredText","config":{"multi":"paragraph, preformatted, heading1, heading2, heading3, heading4, heading5, heading6, strong, em, hyperlink, image, embed, list-item, o-list-item, rtl","label":"설명","placeholder":"용도를 설명해주세요..."}},"items":{"type":"Group","config":{"fields":{"label":{"type":"Text","config":{"label":"표시명"}},"link":{"type":"Link","config":{"label":"링크 주소","placeholder":"전체 URL(href)..."}}},"label":"링크 스펙"}}}}}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":860,"quality":100,"withWebp":true,"linkImagesToOriginal":false,"showCaptions":true,"tracedSVG":true},
    },{
      plugin: require('../node_modules/@devsisters/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://e58e3ba0469746aea208ac4cc0c8ff08@sentry.io/1444779"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    }]
